/**
 * Fetch wrapper for JSON requests.
 * @param {string} url A USVString containing the direct URL of the resource that needs to be fetched
 * @param {RequestInit} [init] An options object containing any custom settings that need to be applied to the request
 * @returns {Promise<any>}
 */
export async function fetchJSON(url: string, init?: RequestInit) {
  const response = await fetch(url, init);
  const contentType = response.headers.get('content-type');

  if (response.ok) {
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else if (contentType && contentType.includes('text/plain')) {
      return response.text();
    } else {
      throw new TypeError(`invalid response type '${contentType}'`);
    }
  }

  // Error handling
  // https://github.com/mycsHQ/configurator-frontend/tree/master/app/src/shared/utilities/FetchAPI
  let message = `${response.status} ${response.statusText}`;
  if (contentType && contentType.includes('application/json')) {
    // Hapi returns error description in body
    let responseBody;
    try {
      responseBody = await response.json();
    } catch {
      message += ` failed to read response stream (json)`;
    }
    if (responseBody) {
      if (responseBody.message) {
        message += ` ${responseBody.message}`;
      } else {
        message += ` ${JSON.stringify(responseBody)}`;
      }
    }
  } else if (contentType && contentType.includes('text/plain')) {
    // other resources might attach error description as text
    let responseBody;
    try {
      responseBody = await response.text();
    } catch {
      message += ` failed to read response stream (text)`;
    }
    if (responseBody) {
      message += ` ${responseBody}`;
    }
  }

  throw new Error(message);
}

export default {
  /**
   * Fetch wrapper for GET requests.
   * NOTE: works only with JSON type of request/response payload.
   * @param {string} url A USVString containing the direct URL of the resource that needs to be fetched
   * @param {Record<string, string>} params query params
   * @param {RequestInit} [init] An options object containing any custom settings that need to be applied to the request
   * @returns {Promise<any>}
   */
  get(
    url: string,
    params: Record<string, string> = {},
    init: RequestInit = {}
  ) {
    if (params) {
      const queryString = new URLSearchParams(params).toString();
      if (queryString) {
        url = `${url}?${queryString}`;
      }
    }

    return fetchJSON(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      ...init,
    });
  },

  /**
   * Fetch wrapper for POST requests.
   * NOTE: works only with JSON type of request/response payload.
   * @param {string} url A USVString containing the direct URL of the resource that needs to be fetched
   * @param {any} data A request payload
   * @param {RequestInit} [init] An options object containing any custom settings that need to be applied to the request
   * @returns {Promise<any>}
   */
  post(url: string, data: any, init: RequestInit = {}) {
    return fetchJSON(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      ...init,
    });
  },

  /**
   * Fetch wrapper for PUT requests.
   * NOTE: works only with JSON type of request/response payload.
   * @param {string} url A USVString containing the direct URL of the resource that needs to be fetched
   * @param {any} data A request payload
   * @param {RequestInit} [init] An options object containing any custom settings that need to be applied to the request
   * @returns {Promise<any>}
   */
  put(url: string, data: any, init: RequestInit = {}) {
    return fetchJSON(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      ...init,
    });
  },

  /**
   * Fetch wrapper for DELETE requests.
   * NOTE: works only with JSON type of request/response payload.
   * @param {string} url A USVString containing the direct URL of the resource that needs to be fetched
   * @param {RequestInit} [init] An options object containing any custom settings that need to be applied to the request
   * @returns {Promise<any>}
   */
  delete(url: string, init: RequestInit = {}) {
    return fetchJSON(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      ...init,
    });
  },
};
