import defaultsDeep from 'lodash/defaultsDeep';
import defaultConfig from './config.default';

export default defaultsDeep(
  {
    environment: 'production',

    //
    // Mention Me - referral program
    //
    mentionMe: {
      endpointLandingpage:
        'https://tag.mention-me.com/api/v2/referreroffer/mm943f3795?',
      endpointCheckout:
        'https://tag.mention-me.com/api/v2/refereefind/mm943f3795?',
      endpointPostPurchase:
        'https://tag.mention-me.com/api/v2/referreroffer/mm943f3795?',
    },

    //
    // Facebook app id
    //
    facebookAppID: '1011429178881226',

    //
    // Payments
    //
    payments: {
      adyen: {
        credentials: {
          clientKey: 'live_7YI3LSM4QZHL7EKA4HUQKIKCFY5VEJ3Z',
          environment: 'live',
        },
      },
    },
    /**
     * Cookie ID for domain
     * @type {string}
     */
    cookieBotDomainGroupId: '08ffd02b-97a2-4206-9357-e73336b7d024',

    rapid7Token: 'fcbf0410-8ec8-4cb4-b00b-724a41de9589',
    /**
     * GA4 Measurement ID
     */
    ga4MeasurementId: {
      de: 'G-PWBHEDKWQ0',
      fr: 'G-ZBB6V1G00D',
      at: 'G-K3S07E5G9V',
      ch: 'G-DPG69HDGPL',
    },
  },

  defaultConfig
);
