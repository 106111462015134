import cfg from 'mycs/config/config.default';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';

export function addScript(showBadge: boolean, locale: string): void {
  let script = getTrustedshopsScript(locale);

  if (script) {
    script.parentNode.removeChild(script);
  }

  let badge = document.querySelector('[id*="trustbadge-container"]'); 
  
  // Remove the badge div if it exists
  if (badge && badge.parentNode) {
    badge.parentNode.removeChild(badge);
  }

  script = document.createElement('script');
  script.async = true;
  script.charset = 'UTF-8';
  script.src = `//widgets.trustedshops.com/js/${getTrustedShopsID(locale)}.js`; 
  script.setAttribute('data-desktop-y-offset', '0');
  script.setAttribute('data-mobile-y-offset', '0');
  script.setAttribute('data-desktop-disable-reviews', 'false');
  script.setAttribute('data-desktop-enable-custom', 'false');
  script.setAttribute('data-desktop-position', 'right');
  script.setAttribute('data-desktop-custom-width', '156');
  script.setAttribute('data-desktop-enable-fadeout', 'false');
  script.setAttribute('data-disable-mobile', 'false');
  script.setAttribute('data-disable-trustbadge', showBadge ? 'false' : 'true'); 
  script.setAttribute('data-mobile-custom-width', '156');
  script.setAttribute('data-mobile-disable-reviews', 'false');
  script.setAttribute('data-mobile-enable-custom', 'false');
  script.setAttribute('data-mobile-position', 'left');
  script.setAttribute('data-mobile-enable-topbar', 'false');
  script.setAttribute('data-mobile-enable-fadeout', 'true');
  script.setAttribute('data-color-scheme', 'light');
  document.body.appendChild(script);
}

export function isTrustedshopsScriptLoaded(locale: string): boolean {
  return !!getTrustedshopsScript(locale);
}

export function shouldDisplayTrustedshopsBadge(pathname: string, locale: string): boolean {
  return isProductOrCheckoutPage(pathname, locale);
}

function isProductOrCheckoutPage(url: string, locale: string): boolean {
  // Pages that should have trustedshops badge, pages below are written in english, they shpould cover all product pages, checkout and cart
  // exception: some pages are in german or french since service url can't find translations for them
  const pagesWithBadge = ['shopping_cart', 'checkout', 'shelves', 'storage','step-shelves', 'tv-cabinet', 'tv-sideboards', 'filing-cabinet',
    'sofas', 'sofas-en-u', 'poufs-de-canape', 'extra-large-sofa', 'armchairs', 'sofas-armchairs' ,'ottomans', 'cushions', 'stufenregale', 'wohnlandschaft', 'polsterhocker', 'rangements', 'product', 'kissen', 'cushion'
   ];

  const translatedUrlToEnglish = RelativeUrlService.getUntranslatedUrl(url, locale);

  return pagesWithBadge.some(pattern => {          
   return translatedUrlToEnglish.includes(pattern)
  }
  );
}

function getTrustedshopsScript(locale: string): any {
  return document.querySelector(`script[src="//widgets.trustedshops.com/js/${getTrustedShopsID(locale)}.js"]`);
}

function getTrustedShopsID(locale: string): string {  
  switch (locale) {
    case 'de_DE':
      return cfg.trustedshopsIdDE;
    case 'de_AT':
      return cfg.trustedshopsIdAT;
    case 'de_CH':
      return cfg.trustedshopsIdCH;
    case 'fr_CH':
      return cfg.trustedshopsIdFrCH;
    case 'fr_FR':
      return cfg.trustedshopsIdFR;
    default:
      return cfg.trustedshopsIdDE;;
  }

}