import { Mode } from 'mycs/shared/services/FlagModeService/FlagModeService';

export default {
  environment: '',

  mycs: {
    telephone: {
      de: '0800 100 40 50',
      at: '0800 070 423',
      ch: '0800 080 060',
      nl: '+31 85 208 0999',
      uk: '0330 808 7475',
      fr: '01.86.65.12.20',
    } as { [country: string]: string },
  },

  /**
   * Advanced mode
   */
  flagModes: {
    notrack: {
      text: 'no track',
      cookie: 'mx-notrack', // IMPORTANT: also used in GTM (Heap, ...)
      showFlag: true,
      showUserInfo: true,
    },
    showroom: {
      text: 'showroom',
      cookie: 'mx-showroom',
      showFlag: true,
      showUserInfo: true,
    },
  } as { [flag: string]: Mode },

  geoSettings: {
    defaultCode: 'de',
    defaultISOCode: 'de-DE',

    countryMappings: {
      uk: 'gb', // Mycs internal country code for UK is 'uk'.
    } as { [country: string]: 'de' | 'at' | 'fr' | 'nl' | 'gb' | 'ch' },

    supportedCountryCodes: ['at', 'ch', 'de', 'fr', 'nl', 'uk'],
    skipLocaleHrefLang: ['nl-NL', 'en-GB'],

    dateSanitizePattern:
      /^(0[1-9]|[12][0-9]|3[01])[-\/.](0[1-9]|1[012])[-\/.]((19|20)\d\d)$/,
    dateParsePattern:
      /^((?:19|20)\d\d)[-\/.](0[1-9]|1[012])[-\/.](0[1-9]|[12][0-9]|3[01])$/,
    /**
     * https://en.wikipedia.org/wiki/Date_format_by_country
     */
    countryDateFormats: {
      at: '$3.$2.$1',
      be: '$3.$2.$1',
      ch: '$3.$2.$1',
      de: '$3.$2.$1',
      fr: '$3/$2/$1',
      lu: '$3.$2.$1',
      nl: '$3-$2-$1',
      uk: '$3/$2/$1',
    } as { [country: string]: string },

    countries_dropdown: {
      at: [{ text: 'Austria', value: 'at' }],
      ch: [{ text: 'Switzerland', value: 'ch' }],
      de: [
        { text: 'Germany', value: 'de' },
        { text: 'Luxembourg', value: 'lu' },
      ],
      fr: [
        { text: 'France', value: 'fr' },
        { text: 'Belgium', value: 'be' },
      ],
      nl: [{ text: 'Netherlands', value: 'nl' }],
      uk: [{ text: 'United Kingdom', value: 'gb' }],
    } as { [country: string]: { text: string; value: string }[] },

    countryCodesToNames: {
      at: 'Austria',
      be: 'Belgium',
      ch: 'Switzerland',
      de: 'Germany',
      fr: 'France',
      lu: 'Luxembourg',
      nl: 'Netherlands',
      uk: 'United Kingdom',
    } as { [country: string]: string },

    placesApiKey: 'AIzaSyDmlny08DyxNJJMMGIZVBQU6s4mrJEor9I',
  },

  API: {
    assetApi: {
      protocol: 'https://',
      host: 'api.mycs.com',
      // ALB is not configured for this path, so /api could not be used here.
      path: '/gw/production/monorepo/assets',
    },

    designApi: {
      protocol: 'https://',
      host: 'api.mycs.com',
      path: '/api/designs',
      explanation: false,
    },

    imageApi: {
      protocol: 'https://',
      host: 'api.mycs.com',
      path: '/api/images',
    },

    livechatApi: {
      protocol: 'https://',
      host: 'lapi.mycs.com',
      path: '/livechat-api',
    },

    translationApi: {
      protocol: 'https://',
      host: 'mycs.com',
      path: '/assets/translations_',
    },

    userApi: {
      protocol: 'https://',
      host: 'api.mycs.com',
      path: '/api/users',
    },

    unrealBBoxes: {
      protocol: 'https://',
      host: 'api.rendering.mycs.com',
      path: '/bboxes',
    },

    reportingApi: {
      protocol: 'https://',
      host: 'lapi.mycs.com',
      path: '/reporting-api/report',
    },

    geolocationApi: {
      protocol: 'https://',
      host: 'lapi.mycs.com',
      path: '/geolocation/location',
    },

    timestampApi: {
      protocol: 'https://',
      host: 'mycs.com',
      path: '/timestamp',
    },

    manualApi: {
      protocol: 'https://',
      host: 'cluster.mycs.com',
      path: '/manual-api',
    },

    checkoutApi: {
      protocol: 'https://',
      host: 'cluster.mycs.com',
      path: '/api/checkout',
    },

    wmsApi: {
      protocol: 'https://',
      host: 'cluster.mycs.com',
      path: '/api/wms',
    },
  } as {
    [country: string]: {
      protocol: string;
      host: string;
      path: string;
      explanation?: boolean;
    };
  },

  //
  // Facebook app id
  //
  facebookAppID: '1011431575547653',

  languages: {
    at: {
      de_AT: {
        code: 'de_AT',
        language: 'german',
        locale: 'de-at',
        short: 'de',
      },
    },
    de: {
      de_DE: {
        code: 'de_DE',
        language: 'german',
        locale: 'de-de',
        short: 'de',
      },
    },
    ch: {
      de_CH: {
        code: 'de_CH',
        language: 'german',
        locale: 'de-ch',
        short: 'de',
      },
      fr_CH: {
        code: 'fr_CH',
        language: 'french',
        locale: 'fr-ch',
        short: 'fr',
      },
    },
    fr: {
      fr_FR: {
        code: 'fr_FR',
        language: 'french',
        locale: 'fr-fr',
        short: 'fr',
      },
    },
    uk: {
      en_GB: {
        code: 'en_GB',
        language: 'english',
        locale: 'en-gb',
        short: 'en',
      },
    },
    nl: {
      nl_NL: {
        code: 'nl_NL',
        language: 'english',
        locale: 'nl-nl',
        short: 'nl',
      },
    },
  } as { [country: string]: { [language: string]: { [key: string]: string } } },

  mainLanguages: {
    de_CH: 'german',
    fr_CH: 'french',
  },

  /**
   * A map of locales that require a language code in the URL as a "folder"
   */
  languageDirectories: {
    fr_CH: 'fr',
  } as { [locale: string]: string },

  /**
   * Pricing settings
   * Some parts of the config are duplicated in Design API, Masterfeed API and Checkout API.
   */
  pricing: {
    at: {
      currencyCode: 'EUR',
      taxPercentage: 20,
      deductTaxOnValidVAT: true,
    },
    be: {
      deductTaxOnValidVAT: true,
    },
    ch: {
      currencyCode: 'CHF',
      taxPercentage: 8.1,
      deductTaxOnValidVAT: false,
    },
    de: {
      currencyCode: 'EUR',
      taxPercentage: 19,
      deductTaxOnValidVAT: false,
    },
    fr: {
      currencyCode: 'EUR',
      taxPercentage: 20,
      deductTaxOnValidVAT: false,
    },
    nl: {
      currencyCode: 'EUR',
      taxPercentage: 19,
      deductTaxOnValidVAT: true,
    },
    uk: {
      currencyCode: 'GBP',
      taxPercentage: 0,
      deductTaxOnValidVAT: true,
    },
    gb: {
      currencyCode: 'GBP',
      taxPercentage: 0,
      deductTaxOnValidVAT: true,
    },
    lu: {
      deductTaxOnValidVAT: true,
    },
  } as {
    [country: string]: {
      currencyCode?: string;
      taxPercentage?: number;
      deductTaxOnValidVAT: boolean;
    };
  },

  /**
   * Furniture types many->one dictionary
   */
  furnitureTypes: {
    shelves: 'shelf',
    wardrobes: 'wardrobe',
    joyns: 'joyn',
    pyllows: 'pyllow',
    flayrs: 'flayr',
    cushions: 'cushion',
  } as { [furnitureType: string]: string },

  categoryPaths: {
    shelves: 'shelf',
    closets: 'closet',
    wardrobes: 'wardrobe',
  },

  eventWeight: {
    configuratorPage: {
      addToCart: {
        de: 0.2713,
        fr: 0.2084,
        ch: 0.3314,
        at: 0.2126,
        uk: 0.0863,
      },
      saveDesignSent: {
        de: 0.2018,
        fr: 0.1493,
        ch: 0.2155,
        at: 0.186,
        uk: 0.069,
      },
    },
    productDisplayPage: {
      addToCart: {
        de: 0.1079,
        fr: 0.0781,
        ch: 0.1294,
        at: 0.0714,
        uk: 0.05,
      },
      saveDesignSent: {
        de: 0.0891,
        fr: 0.0692,
        ch: 0.1,
        at: 0.0506,
        uk: 0.0,
      },
    },
    subCategoryPage: {
      saveDesignSent: {
        de: 0.1407,
        fr: 0.1237,
        ch: 0.1231,
        at: 0.0714,
        uk: 0.25,
      },
    },
  } as {
    [page: string]: {
      addToCart?: { [country: string]: number };
      saveDesignSent: { [country: string]: number };
    };
  },

  //
  // Shipping settings
  //
  shipping: {
    assembly: {
      additionalDays: 14,
    },
  },

  /**
   * Leg types Shelf
   */
  shelfLegTypes: {
    hanging: 'ayr',
  } as { [legType: string]: string },

  //
  // Contentful
  //
  contentful: {
    host: 'xx.mycs.com',
    spaces: {
      frontend: {
        space: '6i6nh3ssbq1v',
        accessToken:
          'fb52ae71f97c1e58d12a5e6a668a3832b5c13ebe148a124aab4427116643d210',
      },
      internal: {
        space: 'okqpu7n5lt9l',
        accessToken:
          '2e3f1576b7b6441a4a2f78b8f10c461f1cc9961c5335424c1076631ed17d1ab4',
      },
    },

    imagesHosts: ['images.contentful.com', 'images.ctfassets.net'],
    imagesCacheHost: 'mycs.com',

    videosHosts: ['videos.contentful.com', 'videos.ctfassets.net'],
    videosCacheHost: 'mycs.com',

    defaultLocale: 'en-US',

    // Locale fallbacks
    fallbackLocales: {
      'de-DE': 'en-US',
      'fr-FR': 'en-US',
      'de-AT': 'de-DE',
      'de-CH': 'de-DE',
      'fr-CH': 'fr-FR',
      'en-US': 'en-US',
      'nl-NL': 'en-US',
    },

    displayEditButton: false,
  },

  //
  // Mailchimp
  //
  mailchimp: {
    newsletterSubscribeUrl:
      '//mycs.us9.list-manage.com/subscribe/post?u=412185930e45d3f4ec6167156&amp;id=',

    langsIds: {
      de: '43c10516c1',
      fr: 'f41ee3392f',
      en: '3790763dac',
    } as { [country: string]: string },
  },

  /** Mention Me - referral program
   */
  mentionMe: {
    endpointLandingpage:
      'https://tag-demo.mention-me.com/api/v2/referreroffer/mm943f3795?',
    endpointCheckout:
      'https://tag-demo.mention-me.com/api/v2/refereefind/mm943f3795?',
    endpointPostPurchase:
      'https://tag-demo.mention-me.com/api/v2/referreroffer/mm943f3795?',
  },

  //
  // LiveChat
  //
  livechat: {
    delay: 2000,
    iframeUrl: 'https://secure.livechatinc.com/licence/6318351/open_chat.cgi',

    groups: {
      at: 13,
      ch: 13,
      de: 13,
      fr: 14,
      nl: 12,
      uk: 12,
    } as { [country: string]: number },
  },

  iconBasePath: '/img/icons/',

  //
  // Payments
  //
  payments: {
    adyen: {
      credentials: {
        clientKey: 'test_JLGUE2UHMFEYPDF4V5Z553HUK4FWACHI',
        environment: 'test',
      },
    },
  },

  // Cookie/LocalStorage settings
  storageSettings: {
    exdays: 365,
    prefix: 'mycs',
    // cookie/localstorage version
    // Bump the version if there are significant changes
    // e.g. structure change, SKU update etc
    version: 1,
    // Attributes that should not be deleted when we bump
    // cookie/localstorage version
    removeWhiteList: [
      'mycsUserId',
      'mycsGuestCustomer',
      'mycsSavedEmail',
      'version',
      'visitorId',
      'user_preferences',
      'ribbon.visitCount',
      'ribbon.madeOrder',
      'ribbon.id',
      'session.id',
      'session.timestamp',
    ],
    UTMs: [
      'utm_medium',
      'utm_source',
      'utm_campaign',
      'utm_content',
      'utm_term',
    ],
    keys: {
      shareDataGTM: 'mycsShareData',
      userID: 'mycsUserId',
      email: 'mycsSavedEmail',
      shareData: 'shareData',
    },
  },

  // Search Engines
  // (https://support.google.com/analytics/answer/2795821#searchEngine)
  searchEngines: [
    'about.com',
    'alice.com',
    'alltheweb.com',
    'altavista.com',
    'aol.com',
    'ask.com',
    'baidu.com',
    'bing.com',
    'cnn.com',
    'daum.net',
    'ekolay.net',
    'eniro.se',
    'google.com',
    'kvasir.no',
    'lycos.com',
    'mamma.com',
    'msn.com',
    'mynet.com',
    'najdi.org.mk',
    'naver.com',
    'ozu.es',
    'pchome.com',
    'rambler.ru',
    'search.com',
    'seznam.cz',
    'szukacz.pl',
    'terra.com',
    'voila.fr',
    'wp.pl',
    'yahoo.com',
    'yam.com',
    'yandex.com',
    'online.onetcenter.org',
    'search.netscape.com',
    'search.virgilio.it',
  ],

  /**
   * RAL to HEX colors
   */
  RALtoRGB: {
    black_9004: '#222223',
    blue_5009: '#225f78',
    blue_2_5000: '#102c54',
    dark_blue_5013: '#08203a',
    dark_green_6009: '#213529',
    dark_red_3005: '#5a191f',
    green_6029: '#006f3d',
    green_grey_7009: '#676f5a',
    grey_7024: '#464b54', // anthracite
    grey_7037: '#77786f',
    light_grey_7004: '#9e9fa3',
    light_yellow_1016: '#cdcb18',
    mint_6033: '#428d78',
    red_3016: '#b52b06',
    stone_grey_7030: '#969992',
    violet_4009: '#a7939c',
    white_9003: '#f3f3f3',
    yellow_1021: '#f6b600',
  } as { [ral: string]: string },

  session: {
    // Time to generate next session id in milliseconds
    timeout: 60 * 60 * 1000,
  },

  mobileConfiguratorTypes: [
    'cushion',
    'flayr',
    'joyn',
    'pyllow',
    'shelf',
    'tyme',
    'wardrobe',
  ],

  /**
   * Option to disable Assembly Service by country code
   */
  disabledAssemblyCountryCodes: [] as string[],

  /**
   * Cookie ID for domain
   */
  cookieBotDomainGroupId: 'd8d3e188-6af0-4c03-ad79-7c496412ad57',

  rapid7Token: 'ad95a4eb-92cd-4a7d-904d-69950c429aab',

  path: {
    homepage: '/',
    shoppingCartPage: '/shopping_cart',
    deliveryPage: '/checkout/delivery',
    validationPage: '/checkout/validation',
    payPage: '/checkout/pay',
    receiptPage: '/checkout/receipt',
  },
  /**
   * GA4 Measurement ID
   */
  ga4MeasurementId: {} as Record<string, string>,

  /* Trustedhops Script */
  trustedshopsIdAT: 'X7B6A6786070143D1E2BC7A3C92407548',
  trustedshopsIdCH: 'X03A8E505568185B1F7E57590EBE2338F',
  trustedshopsIdFrCH: 'X03A8E505568185B1F7E57590EBE2338F',
  trustedshopsIdFR: 'X763163548E7B7B83880DF34C80185F39',
  trustedshopsIdDE: 'X258707BBECA0AD0943838A78DFBDD4AE'
};
