import toPairs from 'lodash/toPairs';

import { BrowserService } from 'mycs/shared/services/BrowserService/BrowserService';
import { MycsURL } from '@mycs/edge-lambdas';
import { RelativeUrlService } from '../RelativeUrlService';
import cfg from 'mycs/config';
import MobileDetectService from 'mycs/shared/services/MobileDetectService/MobileDetectService';
import PathUtils from 'mycs/shared/utilities/PathUtils/PathUtils';

/**
 * Helper to build URL to our API's and 3rd party services
 */
export default class UrlProviderService {
  /**
   * Build the URL based on host, port and path
   */
  static _buildUrl(
    proto: string,
    host: string,
    path: string,
    port?: number | string,
    params?: { [key: string]: string }
  ) {
    //new URL() returns ":" as part of the protocol
    let url = proto.replace('::', ':');
    url += host;
    if (port != null && !['', 80, '80'].includes(port)) url += `:${port}`;
    if (path) url += path;
    if (params)
      url += `?${toPairs(params)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&')}`;
    return url;
  }

  /**
   * Generic method to get URL
   */
  private static getUrl(apiName: string) {
    const api = cfg.API[apiName];

    if (!api) {
      return;
    }

    return this._buildUrl(api.protocol, api.host, api.path);
  }

  /**
   * Get Assets URL
   */
  static getAssetApiUrl() {
    const api = cfg.API.assetApi;
    return this._buildUrl(api.protocol, api.host, api.path);
  }

  /**
   * Get Assets URL
   */
  static getUnrealBboxesApiUrl() {
    const api = cfg.API.unrealBBoxes;
    return this._buildUrl(api.protocol, api.host, api.path);
  }

  /**
   * Get the DesignAPI URL
   */
  static getDesignApiUrl(): string {
    const api = cfg.API.designApi;
    return this._buildUrl(api.protocol, api.host, api.path);
  }

  /**
   * Get the ImageAPI URL
   */
  static getImageApiUrl(): string {
    const api = cfg.API.imageApi;
    return this._buildUrl(api.protocol, api.host, api.path);
  }

  /**
   * Route an image url through Image API
   * Note: when possible, always prefer <SmartImage /> over this method
   */
  static getImageUrl(
    imgPath?: string,
    width = 760,
    height = 760,
    useImageConverter = true
  ) {
    if (!imgPath) return '';

    // Skip in case imgPath is already an Image API url
    const imgApiUrl = this.getImageApiUrl();
    if (imgPath.includes(imgApiUrl)) return imgPath;

    // Build Image API url
    let path = this.getAbsoluteAssetUrl(imgPath);

    const pathname = path.startsWith('http') ? new URL(path).pathname : path;

    const preferedFormat = 'webp';
    const currentFormat = this.getFileExtensionFromURL(pathname);
    let format = useImageConverter ? preferedFormat : currentFormat;

    // Remove host from img urls (needed for Criteo)
    // With the exception of:
    //  ../images/<uuid>/<index> - they do a 302 redirect
    //  ../assets/<image hash>   - Image API doesn't know how to handle them
    if (
      !/api\.mycs\..*\/images\/\d+/.test(path) &&
      !/mycs\..*\/assets\/.*/.test(path)
    ) {
      path = pathname.substr(1); // remove first `/`
    }

    // Newer iOS browsers are a wrapper of Safari and
    // therefore, don't support Webp (even Chrome on iOS)
    // Safari on Dekstop don't support Webp either
    if (MobileDetectService.isIos() || BrowserService.isSafariBrowser()) {
      // Keep original format
      format = currentFormat;
    }

    // Image API fails to read @jfif in the URL.
    if (format === 'jfif') {
      format = '';
    }

    // Image API can't deal with Gif animations
    if (currentFormat === 'gif') {
      return imgPath;
    }

    const resPath = `${imgApiUrl}/fit/${width}/${height}/no/0/plain/${path}${
      format ? `@${format}` : ''
    }`;

    // Check if we use image from prod on dev environment
    if (cfg.environment === 'dev' && /mycs.com/.test(imgPath)) {
      return resPath.replace('mycs.ninja', 'mycs.com');
    }

    return resPath;
  }

  /**
   * Get LiveChat API URL
   */
  static getLivechatApiUrl(): string {
    const api = cfg.API.livechatApi;
    return this._buildUrl(api.protocol, api.host, api.path);
  }

  /**
   * Get the Translation API URL
   */
  static getTranslationsUrl(locale: string, countryCode: string): string {
    const api = cfg.API.translationApi;

    const url = this._buildUrl(
      api.protocol,
      `${countryCode}.${api.host}`,
      api.path
    );
    return `${url + locale.replace('_', '-')}.json`;
  }

  /**
   * Get the UsersAPI URL
   */
  static getUserApiUrl() {
    const api = cfg.API.userApi;
    return this._buildUrl(api.protocol, api.host, api.path);
  }

  /**
   * Returns the reportingApi url
   */
  static getReportingApiUrl() {
    const { protocol, host, path } = cfg.API.reportingApi;
    return this._buildUrl(protocol, host, path);
  }

  /**
   * Returns the geolocationApi url
   */
  static getGeolocationApiUrl() {
    const { protocol, host, path } = cfg.API.geolocationApi;
    return this._buildUrl(protocol, host, path);
  }

  /**
   * Returns the timestampApi url
   */
  static getTimestampApiUrl() {
    const { protocol, host, path } = cfg.API.timestampApi;
    return this._buildUrl(protocol, host, path);
  }

  /**
   * Calculate the URL for the current furniture
   */
  static getFurnitureUrl(locale: string, furnitureType: string, uuid: string) {
    //window.location.href can be used because this method is not used in SSR
    const { protocol, hostname, port } = new URL(window.location.href);
    const path = RelativeUrlService.getConfiguratorUrl(
      furnitureType,
      uuid,
      locale
    );
    return this._buildUrl(`${protocol}//`, hostname, path, port);
  }

  /**
   * Get the product details (PDP) url of a design
   * If baseUrl is not provided it will return a relative url
   */
  static getProductUrl(uuid: string, locale: string, baseUrl?: string) {
    const pageUrl = RelativeUrlService.getPdpUrl(uuid, locale);

    if (!baseUrl) {
      return pageUrl;
    }

    const { protocol, hostname, port } = new URL(baseUrl);

    return this._buildUrl(`${protocol}//`, hostname, pageUrl, port);
  }

  /**
   * Get Material Samples page URL
   */
  static getSamplesUrl(baseUrl: string, locale: string) {
    const { protocol, hostname, port } = new URL(baseUrl);
    const pathname = RelativeUrlService.getSampleboxUrl(locale);

    return this._buildUrl(`${protocol}//`, hostname, pathname, port);
  }

  /**
   * Returns the asset url with the protocol prepended
   */
  static getAbsoluteAssetUrl(imageUrl: string) {
    if (/^\/\/.*/.test(imageUrl)) {
      return `https:${imageUrl}`;
    }

    return imageUrl;
  }

  /**
   * Returns the website URL corresponding to the country code passed in parameter
   */
  private static getWebsiteUrl(baseUrl: string, countryCode: string) {
    const url = new MycsURL(baseUrl);

    url.countryCode = countryCode;

    return url;
  }

  /**
   * Get the contentful images host
   */
  static getCfImagesUrl() {
    const host = cfg.contentful.imagesCacheHost;

    return `https://${host}`;
  }

  /**
   * Get the contentful images host
   */
  static getCfVideosUrl() {
    const host = cfg.contentful.imagesCacheHost;

    return `https://${host}/videos`;
  }

  /**
   * Get link to the assembly manual
   */
  static getAssemblyManualLink(
    furnitureType: string,
    specs: any,
    dimensions: any,
    locale: string
  ) {
    if (!furnitureType || !specs || !dimensions) return '';
    const info = JSON.stringify({
      specs,
      dimensions,
      furniture_type: furnitureType,
    });
    const url = this.getUrl('manualApi');
    const lang = locale.substring(0, 2);
    const params = {
      lang,
      info,
    };

    const searchParams = new URLSearchParams(params);

    return `${url}/generate?${searchParams.toString()}`;
  }

  /**
   * Get Checkout Service URL
   */
  static getCheckoutAPIURL() {
    const api = cfg.API.checkoutApi;
    return this._buildUrl(api.protocol, api.host, api.path);
  }

  /**
   * Get recovery cart URL ('.../warenkorb?uuid=...')
   */
  static getRecoveryCartLink(
    uuids: string[],
    baseUrl: string,
    countryCode: string,
    locale: string
  ) {
    const productList = uuids.join(',');
    const url = this.getWebsiteUrl(baseUrl, countryCode);

    url.pathname = RelativeUrlService.getCartUrl(locale);

    return `${url.toString()}?uuid=${productList}`;
  }

  static getOrderTrackingUrl(orderId: string) {
    return `${this.getUrl('wmsApi')}/order-tracking/${orderId}`;
  }

  static getSetmoreLink = (setmoreShowroomId: string) => {
    return `https://${setmoreShowroomId}.setmore.com`;
  };

  /**
   * Extract the file extension from a given url
   * @returns  an string with the file extension or undefined for non file urls
   */
  static getFileExtensionFromURL(url: string) {
    return /(?:\.([^./]+))?$/.exec(url)?.[1];
  }

  static getPayPageURL(baseUrl: string, countryCode: string, locale: string) {
    const url = this.getWebsiteUrl(baseUrl, countryCode);

    const pathname = RelativeUrlService.getCheckoutPayUrl('', locale);
    //orderID will be added later
    url.pathname = PathUtils.removeSearch(pathname);

    return url.toString();
  }

  static getReceiptPageURL(
    baseUrl: string,
    countryCode: string,
    locale: string
  ) {
    const url = this.getWebsiteUrl(baseUrl, countryCode);

    const pathname = RelativeUrlService.getCheckoutReceiptUrl('', locale);
    //orderID will be added later
    url.pathname = PathUtils.removeSearch(pathname);

    return url.toString();
  }

  static getRenderingApiUrl(): string | undefined {
    const url = this.getUrl('renderingApi');
    return url;
  }
}
