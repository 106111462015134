import { Component } from 'react';
import classNames from 'classnames';
import { Subscription } from 'rxjs';

import { getHeaderFromByLocaleReduxState } from 'mycs/shared/state/slices/headersSlice';
import { useAppSelector } from 'mycs/shared/state/store';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { calculateAverage } from 'mycs/shared/utilities/RatingUtils/RatingUtils';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import Alink from 'mycs/shared/components/Alink/Alink';
import CfEdit from 'mycs/shared/components/CfEdit/CfEdit';
import ClickOutside from 'mycs/shared/components/ClickOutside/ClickOutside';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';
import StarsRating from 'mycs/shared/components/StarsRating/StarsRating';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';

import styles from './MainNav.scss';

const linksPerColumn = 7;
const linksPerRow = 7;
const maxColumns = 5;

type Props = {
  menu: any[];
  locale: string;
  countryCode: string;
};

type State = {
  selectedIndex: number | null;
  activeUrl: string;
  hasHover: boolean;
  avgScore: number | null;
};

export class MainNav extends Component<Props, State> {
  _clickHandlers: any;
  sub: Subscription | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedIndex: null,
      activeUrl: WindowLocationUtils.getPath(),
      hasHover: false,
      avgScore: null,
    };

    // Have only one highlighted item at a time
    this._clickHandlers = {};
  }

  _onHover = (e: any) => this.toggleHover(e, true);
  _onUnhover = (e: any) => this.toggleHover(e, false);
  _close = () => this.close();

  /**
   * Close the menu
   */
  close() {
    if (this.state.selectedIndex !== null) {
      this.setState({ selectedIndex: null });
    }
  }

  /**
   * Detect when some of the links is hovered
   */
  toggleHover(e: any, toggle: boolean) {
    if (this.state.hasHover === toggle) return;
    if (
      !(
        e.target.tagName.toLowerCase() === 'a' ||
        e.target.parentNode.tagName.toLowerCase() === 'a'
      )
    )
      return;

    this.setState({ hasHover: toggle });
  }

  /**
   * Set the active item and expand the menu
   */
  setSelectedItem(index: number) {
    if (this.state.selectedIndex === index) {
      this.close();
    } else {
      this.setState({ selectedIndex: index });
    }
  }

  /**
   * Check if the item is the active menu item
   */
  isActive(item: any): boolean {
    const activeUrl = this.state.activeUrl;

    if (item.url === activeUrl) return true;

    return (item.navGroups || []).some((group: any) => {
      if (group.url === activeUrl) return true;

      return (group.links || []).some((link: any) => link.url === activeUrl);
    });
  }

  /**
   * Get JSX an anchor element
   */
  getLink(link: any): React.ReactNode {
    const linkClass = classNames(styles.link, {
      [styles.link__noUrl]: !link.url,
      [styles.link__active]: this.state.activeUrl === link.url,
      [styles.highlighted]: link.highlighted,
    });

    const rel = link.url && link.url.includes('http') ? 'noopener' : 'prefetch';

    return (
      <Alink
        className={linkClass}
        href={link.url}
        target={link.target}
        rel={rel}
      >
        {link.image ? (
          <SmartImage className={styles.image} src={link.image} />
        ) : null}
        <span>{link.title}</span>
      </Alink>
    );
  }

  /**
   * Get JSX a top-level link
   */
  getTopLevelItem(item: any, index: number): React.ReactNode {
    if (!(index in this._clickHandlers)) {
      this._clickHandlers[index] = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.setSelectedItem(index);
      };
    }

    const itemClass = classNames(styles.item, {
      [styles.item__selected]: index === this.state.selectedIndex,
      [styles.item__active]: this.isActive(item),
      [styles.item__notablet]: item.hideOnTablet,
    });

    // If no submenus, act as a regular link
    const hasSubmenu = item.navGroups && item.navGroups.length;
    const link = hasSubmenu ? { title: item.title } : item;
    const onClick = hasSubmenu ? this._clickHandlers[index] : null;

    // Top-level items
    return (
      <div
        className={itemClass}
        key={index}
        data-track-path={`MainMenu{${item.title}}`}
      >
        <span onClick={onClick}>{this.getLink(link)}</span>
      </div>
    );
  }

  /**
    * This method is used for displaying rating
    */
  getAdditionalText(additionalTextField: any): React.ReactNode {
    if (additionalTextField === undefined) return <></>

    return (
      <div className={styles.rating}>
        <span className={styles.ratingText}>{additionalTextField}: {this.state.avgScore}/5</span>
        <StarsRating rating={this.state.avgScore} />
      </div>
    )
  }

  /**
   * Get JSX for a group of links
   */
  getGroup(group: any, index: number, linksLimit: number): React.ReactNode {
    const links = (group.links || []).map((link: any, i: number) => {
      return (
        <div key={i} data-track-path={link.title}>
          {this.getLink(link)}
        </div>
      );
    });

    const columns = [];
    for (let i = 0, len = links.length; i < len; i += linksLimit) {
      columns.push(
        <div className={styles.column} key={i}>
          {links.slice(i, i + linksLimit)}
        </div>
      );
    }

    return (
      <div className={styles.group} key={index} data-track-path={group.title}>
        <div className={styles.heading}>{this.getLink(group)}</div>
        <div>{this.getAdditionalText(group.additionalTextField)}</div>
        <div className={styles.columns}>{columns}</div>
      </div>
    );
  }

  /**
   * Mark the current link as active
   */
  updateActiveItem(activeUrl: string) {
    if (this.state.activeUrl !== activeUrl) {
      this.setState({ activeUrl });
      this.close();
    }
  }

  /**
   * Get JSX for a sub-menu
   */
  getSubmenu(item: any, index: number) {
    const navGroups = item.navGroups || [];

    const linksLimit =
      navGroups.length === 1
        ? linksPerRow
        : navGroups.length < maxColumns
          ? linksPerColumn
          : 100;

    const containsImages = navGroups.some(
      (navGroup: any) =>
        navGroup.links && navGroup.links.some((link: any) => link.image)
    );

    const submenuClass = classNames(styles.submenu, {
      [styles.submenu__selected]: index === this.state.selectedIndex,
      [styles.submenu__images]: containsImages,
      [styles.submenu__flat]:
        navGroups.length === 1 &&
        (navGroups[0].links || []).length <= linksLimit,
      [styles.threeColumn]: navGroups.length >= maxColumns,
    });

    // A submenu
    return (
      <div
        className={submenuClass}
        key={index}
        data-track-path={`MainMenu/${item.title}`}
        data-testid="submenu"
      >
        <div className={styles.edit}>
          <CfEdit entryId={item._id} isStatic />
        </div>

        {navGroups.map((navGroup: any, idx: any) =>
          this.getGroup(navGroup, idx, linksLimit)
        )}
      </div>
    );
  }

  /**
   * Load data from Contentful when the component is mounted
   */
  componentDidMount() {
    const onUrlChange = () => {
      this.updateActiveItem(WindowLocationUtils.getPath());
    };

    this.sub = WindowLocationUtils.onUrlChange(onUrlChange);
    ContentfulService.getCustomerTestimonialsList(this.props.locale, this.props.countryCode).then((data) => {
      //ignore invalid data
      if (!data || !Array.isArray(data)) {
        return;
      }

      const average = calculateAverage(data.filter((review: any) => review.showOnWebsite).map((review: any) => review.rating));
      this.setState({ avgScore: average });
    });
  }

  /**
   * On unmount
   */
  componentWillUnmount() {
    this.sub && this.sub.unsubscribe();
  }

  render() {
    const menu = this.props.menu || [];

    // Top-level menu
    const items = menu.map(this.getTopLevelItem, this);

    // Groups of columns (sub-menus)
    const submenus = menu.map(this.getSubmenu, this);

    const menuClass = classNames(styles.menu, {
      [styles.menu__hover]: this.state.hasHover,
      [styles.menu__expanded]: this.state.selectedIndex !== null,
    });

    // The whole component
    return (
      <ClickOutside
        onClickOutside={this._close}
        clickOutsideWhiteList={['[class^="Ribbon__ribbon"]']}
      >
        <div
          className={menuClass}
          onMouseOver={this._onHover}
          onMouseOut={this._onUnhover}
        >
          <div className={styles.items} onClick={this._close}>
            {items}
          </div>

          <div className={styles.submenus}>
            <div className={classNames('container', styles.container)}>
              {submenus}
            </div>
          </div>
        </div>
      </ClickOutside>
    );
  }
}

export default function MainNavWrapper() {
  const { locale, countryCode } = useLocale();

  const menu = useAppSelector(
    (state) => getHeaderFromByLocaleReduxState(state, locale)?.submenus ?? []
  );

  return <MainNav menu={menu} locale={locale} countryCode={countryCode} />;
}
