import { Component } from 'react';
import ReactModal from 'react-modal';

let ModalComponent = ReactModal;

/**
 * SSR is broken for every page that used 'react-modal' which uses `typeof window !== 'undefined'` to handle SSR and
 * we are mocking the window object with jsdom which causes the `react-modal` component to think it is in the browser.
 */
if (MYCS_IS_SSR_BUILD) {
  class MockedModal extends Component<{}> {
    static setAppElement() {}

    render() {
      return this.props.children;
    }
  }
  //@ts-ignore
  ModalComponent = MockedModal;
}

export default ModalComponent;
