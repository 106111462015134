import classNames from 'classnames';

import { getPageContentByKeyFromReduxState } from 'mycs/shared/state/slices/pageSlice';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { SignupSource } from 'mycs/api/UserAPI';
import { store } from 'mycs/shared/state/store';
import { updateUrlWithoutReload } from 'mycs/router/history';
import { useUser } from 'mycs/hooks/useUser';
import { useUserTools } from 'mycs/hooks/useUserTools';
import Button from 'mycs/shared/components/Button/Button';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import HelpStore from 'mycs/shared/stores/HelpStore/HelpStore';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Icon from 'mycs/shared/components/Icon/Icon';
import Logger from 'mycs/shared/services/Logger';
import PathUtils from 'mycs/shared/utilities/PathUtils/PathUtils';
import SaveDesignEmailService from 'mycs/shared/services/AnalyticsService/SaveDesignEmailService';
import SaveDesignForm from 'mycs/shared/components/SaveDesignForm/SaveDesignForm';

import styles from './ExitSaveDesignForm.scss';

type Props = {
  onClose: () => void;
  locale: string;
  countryCode: string;
  pathname: string;
};

export default function ExitSaveDesignForm({
  locale,
  pathname,
  countryCode,
  onClose,
}: Props) {
  const { user, mutateUser } = useUser();
  const { signUpAndAddDesigns } = useUserTools(user, mutateUser);
  const pageData = getPageContentByKeyFromReduxState<any>(store.getState(), {
    locale,
    pathname,
  });

  const submit = async (email: string) => {
    // TODO (Valentyn) This is rather a tmp solution.
    // Should be updated once the app has a proper state management.
    let designPromise;
    if (RelativeUrlService.isConfiguratorPage(pathname, locale)) {
      designPromise = DesignApiService.saveCurrentDesign().then(
        (design: any) => {
          updateUrlWithoutReload(
            RelativeUrlService.getUrlForUuid(pathname, design.uuid, locale),
            false
          );

          const price = design.price[countryCode];
          return [design, price];
        }
      );
    } else if (RelativeUrlService.isPdpPage(pathname, locale)) {
      const uuid = PathUtils.getPathUuid(pathname, locale);
      if (!uuid) {
        Logger.error(
          new Error('[ExitSaveDesignForm] Could not determine uuid')
        );
        return;
      }
      designPromise = DesignApiService.getRendering(uuid, countryCode).then(
        (designs) => {
          const design = designs[0];
          const price = design.price;

          return [design, price];
        }
      );
    } else {
      Logger.error(new Error('[ExitSaveDesignForm] Unknown page type'));
      return;
    }

    const [design, price] = await designPromise;
    await signUpAndAddDesigns({ email, uuids: [design.uuid] });

    SaveDesignEmailService.sendSaveDesignEmail(
      design,
      price,
      locale,
      countryCode,
      pathname,
      pageData
    );

    onClose();
  };

  const toggleChat = () => {
    HelpStore.showChat();
    onClose();
  };

  const source = RelativeUrlService.isConfiguratorPage(pathname, locale)
    ? SignupSource.ExitIntentConfigurator
    : SignupSource.ExitIntentPDP;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4>{I18nUtils.localize(locale, 'Get this design via e-mail')}</h4>

        <Button
          iconName="general/close.svg"
          onClick={onClose}
          className={styles.closeButton}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.form}>
          <p>
            {I18nUtils.localize(
              locale,
              'static_content/my-send-design-via-email/my-exit-intent-dialog/notification'
            )}
          </p>

          <SaveDesignForm onSubmit={submit} source={source} />
        </div>
      </div>

      <div className={styles.footer}>
        <p>
          {I18nUtils.localize(
            locale,
            'static_content/my-send-design-via-email/my-exit-intent-dialog/hilfe'
          )}
        </p>

        <div className="row">
          <div className="col-sm-6">
            <div className={styles.iconText}>
              <span className={styles.icon}>
                <Icon iconName="support/phone.svg" />
              </span>
              <span>
                {I18nUtils.localize(
                  locale,
                  'static_content/my-send-design-via-email/my-exit-intent-dialog/phone-number'
                )}
              </span>
            </div>
          </div>

          <div className="col-sm-6">
            <div
              className={classNames(styles.iconText, styles.clickable)}
              onClick={toggleChat}
            >
              <span className={styles.icon}>
                <Icon iconName="support/chat.svg" />
              </span>
              <span>
                {I18nUtils.localize(
                  locale,
                  'static_content/my-send-design-via-email/my-exit-intent-dialog/chat'
                )}
              </span>
            </div>
          </div>
        </div>

        <p className={styles.footNote}>
          {I18nUtils.localize(
            locale,
            'static_content/my-send-design-via-email/my-exit-intent-dialog/hours'
          )}
        </p>
      </div>
    </div>
  );
}
