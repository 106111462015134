import { Navigate, useLocation, useParams } from 'react-router-dom';
import loadable from '@loadable/component';
import { CfProductGrid } from '@mycs/contentful';

import { AsyncLoader } from 'mycs/shared/components/AsyncLoader/AsyncLoader';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { setDesignsData } from 'mycs/shared/state/slices/designsSlice';
import { setPage } from 'mycs/shared/state/slices/pageSlice';
import { setRibbons } from 'mycs/shared/state/slices/ribbonsSlice';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import Logger from 'mycs/shared/services/Logger';
import RibbonService from 'mycs/shared/services/RibbonService/RibbonService';
import {
  sofaRoute,
  shelfRoute,
  cushionRoute,
  wardrobeRoute,
} from './ConfiguratorRoutes';
import { RouteHandlerType } from './Utils';

export type componentProps = {
  locale: string;
  countryCode: string;
  pathname: string;
  params: Record<string, any>;
};

const OrderTracking = loadable(
  () =>
    import(
      /* webpackChunkName: "order-tracking" */ 'mycs/account/order-tracking/components/OrderTracking/OrderTracking'
    ),
  {
    fallback: <AsyncLoader />,
    ssr: false,
  }
);

const MyDesigns = loadable(
  () =>
    import(
      /* webpackChunkName: "mydesigns" */ 'mycs/landing-page/components/MyDesigns/MyDesigns'
    ),
  {
    fallback: <AsyncLoader />,
    ssr: false,
  }
);

const ProductDetailsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "pdp" */ 'mycs/pdp/components/ProductDetailsPage/ProductDetailsPage'
    ),
  {
    fallback: <AsyncLoader />,
  }
);

const Cart = loadable(
  () => import(/* webpackChunkName: "checkout" */ 'mycs/cart/Cart/Cart'),
  {
    fallback: <AsyncLoader />,
    ssr: false,
  }
);

const CheckoutRoutes = loadable(
  () =>
    import(/* webpackChunkName: "checkout" */ 'mycs/checkout/CheckoutRoutes'),
  {
    fallback: <AsyncLoader />,
    ssr: false,
  }
);

const SampleBox = loadable(
  () =>
    import(
      /* webpackChunkName: "samplebox" */ 'mycs/samplebox/components/SampleBox/SampleBox'
    ),
  {
    fallback: <AsyncLoader />,
  }
);

const ClosedStorePage = loadable(
  () =>
    import(
      /* webpackChunkName: "closed-store" */ 'mycs/landing-page/components/ClosedStorePage/ClosedStorePage'
    ),
  {
    fallback: <AsyncLoader />,
  }
);

const CookieDeclaration = loadable(
  () =>
    import(
      /* webpackChunkName: "cookie-declaration" */ 'mycs/landing-page/components/CookieDeclaration/CookieDeclaration'
    ),
  {
    fallback: <AsyncLoader />,
  }
);

const MainPage = loadable(
  () =>
    import(
      /* webpackChunkName: "mainpage" */ 'mycs/landing-page/components/MainPage/MainPage'
    ),
  {
    fallback: <AsyncLoader />,
  }
);

const routes: Record<string, RouteHandlerType> = {
  // Cushion Configurator
  '/cushion/:uuid': cushionRoute('cushion'),
  // Joyn Sofa
  '/joyn_old/:uuid': {
    noPageView: true,
    render: <RedirectOldJoynSofa />,
  },
  '/joyn/:uuid': sofaRoute('joyn'),
  // Pyllow Sofa
  '/pyllow/:uuid': sofaRoute('pyllow'),
  // Flayr Sofa
  '/flayr/:uuid': sofaRoute('flayr'),
  // Shelf
  '/shelf/:uuid': shelfRoute('shelf'),
  // Wardrobe
  '/wardrobe/:uuid': wardrobeRoute('wardrobe'),
  '/order-tracking': {
    resolve: ({ pathname, locale, countryCode }: componentProps) =>
      ContentfulService.getMainPage(pathname, locale, countryCode),
    render: <OrderTracking />,
  },
  '/my-designs': {
    resolve: ({ pathname, locale, countryCode }: componentProps) =>
      ContentfulService.getMainPage(pathname, locale, countryCode),
    render: <MyDesigns />,
  },
  // Product Details
  '/product/:uuid': {
    failRoute: '/about-our-configurators?msg=Design not found',
    mobileFailRoute: '/',
    criteoFailRoute: '/my-designs?msg=Design not found',
    resolve({ params, locale, countryCode, pathname }: componentProps) {
      const {
        getData,
      } = require('mycs/pdp/services/PdpDataService/PdpDataService');

      return Promise.all([
        getData(params.uuid, locale, countryCode).then(
          preparePageState(locale, pathname)
        ),
        loadRibbonsState(locale, countryCode),
      ]);
    },
    render: <ProductDetailsPage />,
  },
  '/shopping_cart': {
    resolve: ({ pathname, locale, countryCode }: componentProps) =>
      ContentfulService.getShoppingCartPage(
        pathname,
        locale,
        countryCode,
        true
      ),
    render: <Cart />,
  },
  '/checkout/*': {
    resolve: ({ pathname, locale, countryCode }: componentProps) =>
      ContentfulService.getMainPage(pathname, locale, countryCode),
    render: <CheckoutRoutes />,
  },
  '/samples': {
    noPageView: true,
    render: <Navigate to="/materialproben" />,
  },
  '/samplebox': {
    resolve: ({ pathname, locale, countryCode }: componentProps) => {
      const SampleboxDataService =
        require('mycs/samplebox/services/SampleboxDataService').default;
      return SampleboxDataService.getData(pathname, locale, countryCode);
    },
    render: <SampleBox />,
  },
  '/showroom': {
    noPageView: true,
    render: <Navigate to="/showroom-berlin" />,
  },
  // Legacy About our configurator
  // Note: keep for legacy URLs used all across the website where a hardcoded filter might have been used
  '/about-our-configurators/:filter': {
    noPageView: true,
    render: <RedirectToAboutConfiguratorsPage />,
  },
  '/closed-store': {
    render: <ClosedStorePage />,
  },
  '/cookie-declaration': {
    render: <CookieDeclaration />,
  },
  // Redirect URLs with trailing slashes
  '/:anything+/': {
    noPageView: true,
    render: <RemoveTrailingSlashes />,
  },
  // Catch-all route, should go last.
  // It ignores trailing slashes.
  '/*': {
    resolve: ({ pathname, locale, countryCode }: componentProps) => {
      const lpDataPromise = ContentfulService.getMainPage(
        pathname,
        locale,
        countryCode
      ).catch(() => {
        Logger.error(
          new Error(
            `${pathname} (${locale}, ${countryCode}) rendered a 404 page`
          )
        );
        return ContentfulService.get404Page(locale, countryCode);
      });

      return Promise.all([
        lpDataPromise.then(preparePageState(locale, pathname)),
        lpDataPromise.then(
          loadDesignsState({ locale, countryCode, pathname: pathname })
        ),
        loadRibbonsState(locale, countryCode),
      ]).then((result) => result.flat());
    },
    render: <MainPage />,
  },
};

export default routes;

function preparePageState(locale: string, pathname: string) {
  return (data: any) => ({
    slice: 'page',
    action: setPage,
    payload: { data, locale, pathname },
  });
}

function loadDesignsState({
  countryCode,
  locale,
  pathname,
}: {
  countryCode: string;
  locale: string;
  pathname: string;
}) {
  return (data: { page: { uuids?: string[]; content?: any[] } }) => {
    const { page } = data;

    //get all Product grids
    const grids = (page.content?.filter(
      (content) => content._contentType === 'wizardFurnitureColor'
    ) ?? []) as CfProductGrid[];
    const DesignApiService =
      require('mycs/shared/services/DesignApiService/DesignApiService').default;

    // Dynamic subcategory
    if (DesignApiService.shouldRenderCategoryData(grids?.[0], countryCode)) {
      const category = grids[0].category;
      const primaryColorGroup = grids[0].primaryColorGroup;
      const gridBannerUUIds = DesignApiService.getGridBannersUUIDs(
        grids[0].gridBanners
      );

      return Promise.all([
        DesignApiService.getDesignsByCategory(category, countryCode, {
          primary_color_group: primaryColorGroup,
        })
          .then((designs: any) => ({
            slice: 'designs',
            action: setDesignsData,
            payload: { pathname, locale, designs, category, primaryColorGroup },
          }))
          //avoids breaking other parallel loads
          .catch((err: any) => {
            err.message = `loadDesignsState: (${countryCode}) Couldn't load designs: ${err}`;
            Logger.error(err);
          }),
        DesignApiService.getDesignsByUuids(gridBannerUUIds, countryCode, false)
          .then((designs: any) => ({
            slice: 'designs',
            action: setDesignsData,
            payload: { pathname, locale, designs },
          }))
          //avoids breaking other parallel loads
          .catch((err: any) => {
            err.message = `loadDesignsState: (${countryCode}) Couldn't load designs: ${err}`;
            Logger.error(err);
          }),
      ]);
    }

    // Static subcategory
    const gridUUIDs = grids.flatMap((grid) => grid.uuids);
    const uuids = gridUUIDs.length > 0 ? gridUUIDs : page.uuids;

    if (!uuids) return;

    const uniqueUuids = [...new Set(uuids)];

    return (
      DesignApiService.getDesignsByUuids(uniqueUuids, countryCode, false)
        .then((designs: any) => ({
          slice: 'designs',
          action: setDesignsData,
          payload: { pathname, locale, designs },
        }))
        //avoids breaking other parallel loads
        .catch((err: any) => {
          err.message = `loadDesignsState: (${countryCode}) Couldn't load designs: ${err}`;
          Logger.error(err);
        })
    );
  };
}

function loadRibbonsState(locale: string, countryCode: string) {
  return (
    RibbonService.loadRibbons(locale, countryCode)
      .then((ribbons) => ({
        slice: 'ribbon',
        action: setRibbons,
        payload: { ribbons, locale },
      }))
      //avoids breaking other parallel loads
      .catch((err) => {
        err.message = `loadRibbonsState: (${locale}) Couldn't load ribbons: ${err}`;
        Logger.error(err);
      })
  );
}

function RedirectOldJoynSofa() {
  const { uuid } = useParams<{ uuid: string }>();
  const { search } = useLocation();
  const { locale } = useLocale();

  return (
    <Navigate
      to={RelativeUrlService.getTranslatedUrl(`/joyn/${uuid}${search}`, locale)}
    />
  );
}

function RedirectToAboutConfiguratorsPage() {
  const { locale } = useLocale();
  return (
    <Navigate
      to={RelativeUrlService.getTranslatedUrl(
        '/about-our-configurators',
        locale
      )}
    />
  );
}

function RemoveTrailingSlashes() {
  const { search, pathname } = useLocation();

  return <Navigate to={`${pathname.replace(/[/]$/, '')}${search}`} />;
}
